import { System } from "ecsy";
import { Advance } from "../components/advance";
import { Turn } from "../components/turn";

export class AdvanceTurn extends System {
  execute() {
    this.queries.advance.results.forEach((a) => {
      this.queries.turn.results.forEach((t) => {
        t.getComponent(Turn).state.send("advance");

        a.removeComponent(Advance);
        a.remove();
      });
    });
  }

  static queries = {
    advance: { components: [Advance] },
    turn: { components: [Turn] },
  };
}
