import { Component, Entity } from "ecsy";

export enum CardStatus {
  DrawPile = "DrawPile",
  DiscardPile = "DiscardPile",
  Hand = "Hand",
}

/** A card in the player's deck */
export class InDeck extends Component {
  constructor(public status: CardStatus = CardStatus.DrawPile) {
    super();
  }

  copy(src: InDeck) {
    this.status = src.status;
  }

  reset() {
    this.status = CardStatus.DrawPile;
  }
}

export function drawPile(es: Array<Entity>) {
  return es.filter(
    (e) =>
      e.hasComponent(InDeck) &&
      e.getComponent(InDeck).status === CardStatus.DrawPile
  );
}

export function hand(es: Array<Entity>) {
  return es.filter(
    (e) =>
      e.hasComponent(InDeck) &&
      e.getComponent(InDeck).status === CardStatus.Hand
  );
}

export function discardPile(es: Array<Entity>) {
  return es.filter(
    (e) =>
      e.hasComponent(InDeck) &&
      e.getComponent(InDeck).status === CardStatus.DiscardPile
  );
}
