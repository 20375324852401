import { createMachine, state, transition, interpret } from "robot3";

export enum TurnState {
  PlayerStart = "playerStart",
  PlayerTurn = "playerTurn",
  PlayerEnd = "playerEnd",

  AIStart = "aiStart",
  AITurn = "aiTurn",
  AIEnd = "aiEnd",
}

export enum TurnEvent {
  Advance = "advance",
}

const machine = createMachine({
  playerStart: state(transition(TurnEvent.Advance, TurnState.PlayerTurn)),
  playerTurn: state(transition(TurnEvent.Advance, TurnState.PlayerEnd)),
  playerEnd: state(transition(TurnEvent.Advance, TurnState.AIStart)),
  aiStart: state(transition(TurnEvent.Advance, TurnState.AITurn)),
  aiTurn: state(transition(TurnEvent.Advance, TurnState.AIEnd)),
  aiEnd: state(transition(TurnEvent.Advance, TurnState.PlayerStart)),
});

export const turn = () => interpret(machine as any, () => {});
