import { System, Not } from "ecsy";
import { Play } from "../components/play";
import { Resource } from "../components/resource";
import { ResourcePool } from "../components/resource-pool";

/** add resource when resource card is played */
export class AddResource extends System {
  execute() {
    if (this.queries.resources.changed) {
      this.queries.resources.results.forEach((r) => {
        const pool = this.queries.pool.results[0]?.getMutableComponent(
          ResourcePool
        );
        const toAdd = r.getComponent(Resource);
        pool.value[toAdd.type] += toAdd.amount;
      });
    }
  }

  static queries = {
    pool: { components: [ResourcePool] },
    resources: {
      components: [Play, Resource],
      listen: {
        changed: [Play, Not(Resource)] as any,
      },
    },
  };
}
