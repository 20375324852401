import { System, Not } from "ecsy";
import { Turn } from "../components/turn";
import { Play } from "../components/play";
import { InDeck, CardStatus } from "../components/in-deck";
import { TurnState } from "../turn";

/** discard any cards which have been played by the player */
export class DiscardPlayed extends System {
  execute() {
    if (
      this.queries.turn.results[0]?.getComponent(Turn).state.machine.current ===
      TurnState.PlayerTurn
    ) {
      this.queries.played.added?.forEach((card) => {
        card.getMutableComponent(InDeck).status = CardStatus.DiscardPile;
        card.removeComponent(Play);
      });
    }
  }

  static queries = {
    turn: { components: [Turn] },
    played: {
      components: [Play, InDeck],
      listen: { added: [Play, Not(InDeck)] as any },
    },
  };
}
