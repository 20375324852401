import { System, Entity } from "ecsy";
import { InDeck, drawPile } from "../../components/in-deck";
import * as P from "pixi.js";
import app from "../../pixi/app";
import { Name } from "../../components/name";
import { rectangle } from "../../pixi/draw";

interface NumberButton {
  text: P.Text;
  button: P.Graphics;
}

function numberButton(): NumberButton {
  const fill = new P.FillStyle();
  const line = new P.LineStyle();

  fill.color = 0xff4081;
  fill.visible = true;
  const button = rectangle(32, 32, { fill, line });
  const text = new P.Text("0", {
    fontSize: 16,
    fontFamily: "Monoid, monospaced",
  });

  text.resolution = 1.5;
  text.x = button.width / 2 - text.width;
  text.y = button.height / 2 - text.height / 2 + 3;

  button.visible = true;
  button.interactive = true;
  button.addChild(text);

  return { button, text };
}

interface Modal {
  text: P.Text;
  modal: P.Graphics;
}

function modal(): Modal {
  const fill = new P.FillStyle();
  const line = new P.LineStyle();

  const width = 360;
  const height = 80;

  fill.color = 0x37474f;
  fill.visible = true;

  line.color = 0xff4081;
  line.visible = true;
  line.width = 4;

  const modal = rectangle(width, height, { fill, line });

  const text = new P.Text("", {
    fontSize: 14,
    fontFamily: "Monoid, monospaced",
    wordWrap: true,
    wordWrapWidth: width - 20,
    fill: 0xffffff,
    resolution: 1.5,
  });

  text.x = modal.x + 8;
  text.y = modal.y + 8;

  return { modal, text };
}

function generateCardsDigest(cards: Array<Entity>): string {
  const counts = cards
    .map((c) => c.getComponent(Name))
    .reduce((memo, x) => {
      if (memo[x.value] != null) {
        memo[x.value]++;
        return memo;
      } else {
        memo[x.value] = 1;
        return memo;
      }
    }, {} as Record<string, number>);

  return Object.keys(counts)
    .map((cardName) => `${counts[cardName]}x ${cardName}`)
    .join("\n");
}

export class RenderDeck extends System {
  private app: P.Application = app;

  private drawPileButton: NumberButton = numberButton();
  private drawPileModal: Modal = modal();
  private drawPileExpanded: boolean = false;

  private deckCountButton: NumberButton = numberButton();
  private deckModal: Modal = modal();
  private deckExpanded: boolean = false;

  private positionDrawPileButton() {
    const { button } = this.drawPileButton;
    button.x = this.app.screen.left + 16;
    button.y = this.app.screen.bottom - button.height - 8;
  }

  private initDrawPileButton() {
    const { button } = this.drawPileButton;

    this.positionDrawPileButton();
    button.interactive = true;

    this.app.stage.addChild(button);

    button.addListener("pointerdown", () => {
      this.drawPileExpanded = !this.drawPileExpanded;
    });
  }

  private initDeckCountButton() {
    const { button } = this.deckCountButton;
    button.x = this.app.screen.right - button.width - 16;
    button.y = this.app.screen.top + 8;
    button.interactive = true;

    this.app.stage.addChild(button);

    button.addListener("pointerdown", () => {
      this.deckExpanded = !this.deckExpanded;
    });
  }

  private initDeckModal() {
    this.deckModal.modal.visible = false;
    this.deckModal.text.visible = false;

    this.app.stage.addChild(this.deckModal.modal);
    this.app.stage.addChild(this.deckModal.text);
  }

  private initDrawPileModal() {
    const { text, modal } = this.drawPileModal;

    text.visible = false;
    modal.visible = false;

    this.app.stage.addChild(modal);
    this.app.stage.addChild(text);
  }

  init() {
    this.initDrawPileButton();
    this.initDrawPileModal();

    this.initDeckCountButton();
    this.initDeckModal();
  }

  private updateDrawPileButton(deck: Array<Entity>): void {
    const { text } = this.drawPileButton;
    const label = `${drawPile(deck).length}`;
    if (text.text !== label) {
      text.text = label;
    }
  }

  private updateDeckCountButton(deck: Array<Entity>): void {
    const { text } = this.deckCountButton;
    const label = `${deck.length}`;

    if (text.text !== label) {
      text.text = label;
    }
  }

  private deckChanged(): boolean {
    return (
      this.queries.deck.added !== undefined ||
      this.queries.deck.removed !== undefined
    );
  }

  private setDeckModalVisibility(v: boolean) {
    this.deckModal.modal.visible = v;
    this.deckModal.text.visible = v;
  }

  private setDrawPileModalVisibility(v: boolean) {
    this.drawPileModal.modal.visible = v;
    this.drawPileModal.text.visible = v;
  }

  private positionDrawPileModal() {
    const { button } = this.drawPileButton;
    const { modal, text } = this.drawPileModal;

    modal.height = text.height + 16;

    modal.y = button.y - modal.height;
    modal.x = button.x + button.width + 4;

    text.x = modal.x + 8;
    text.y = modal.y + 8;
  }

  private positionDeckModal() {
    const { button } = this.deckCountButton;
    const { modal, text } = this.deckModal;

    modal.y = button.y + button.height;
    modal.x = button.x - modal.width + 4;

    text.x = modal.x + 8;
    text.y = modal.y + 8;

    modal.height = text.height + 16;
  }

  private setDrawPileModalText() {
    const { text } = this.drawPileModal;
    const pileDescription = generateCardsDigest(
      drawPile(this.queries.deck.results)
    );
    if (text.text !== pileDescription) {
      text.text = pileDescription;
    }
  }

  private setDeckModalText() {
    const { text } = this.deckModal;
    const deckDescription = generateCardsDigest(this.queries.deck.results);
    if (text.text !== deckDescription) {
      text.text = deckDescription;
    }
  }

  execute() {
    if (!this.deckExpanded) {
      this.setDeckModalVisibility(false);
    } else {
      this.positionDeckModal();
      this.setDeckModalVisibility(true);
    }

    if (!this.drawPileExpanded) {
      this.setDrawPileModalVisibility(false);
    } else {
      this.setDrawPileModalText();
      this.positionDrawPileModal();
      this.setDrawPileModalVisibility(true);
    }

    if (this.deckChanged()) {
      this.updateDeckCountButton(this.queries.deck.results);
      this.updateDrawPileButton(this.queries.deck.results);

      if (this.deckModal.modal.visible) {
        this.setDeckModalText();
      }
    }
  }

  static queries = {
    deck: {
      components: [InDeck],
      listen: {
        added: true,
        removed: true,
      },
    },
  };
}
