import { Name } from "../components/name";
import { Resource } from "../components/resource";
import { EntityCreator } from "../entity-creator";
import { LonelyResource } from "../resource";

export const makeNaniteResourceCard = (creator: EntityCreator) => ({
  new: () =>
    creator
      .createEntity()
      .addComponent(Name, { value: `Resource: ${LonelyResource.Nanite}` })
      .addComponent(Resource, { type: LonelyResource.Nanite, amount: 1 }),
});
