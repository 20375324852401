import { System } from "ecsy";
import { ResourcePool } from "../../components/resource-pool";
import * as P from "pixi.js";
import app from "../../pixi/app";
import { LonelyResource } from "../../resource";

function resourcePoolDigest(pool: ResourcePool): string {
  return (Object.keys(pool.value) as LonelyResource[])
    .filter((key) => pool.value[key] > 0)
    .map((key) => `${key}: ${pool.value[key]}`)
    .join("\n");
}

export class RenderResources extends System {
  private app = app;
  private resourceText: P.Text = new P.Text("", {
    fontFamily: "Monoid, monospace",
    fontSize: 12,
    fill: 0xffffff,
    resolution: 1.5,
  });

  init() {
    this.resourceText.tint = 0xff4081;
    this.resourceText.x = 8;
    this.resourceText.y = 8;
    this.app.stage.addChild(this.resourceText);
  }

  execute() {
    if (this.queries.resourcePool.changed) {
      this.resourceText.text = resourcePoolDigest(
        this.queries.resourcePool.results.map((e) =>
          e.getComponent(ResourcePool)
        )[0]
      );
    }
  }

  static queries = {
    resourcePool: {
      components: [ResourcePool],
      listen: { changed: true },
    },
  };
}
