import { System } from "ecsy";
import * as P from "pixi.js";
import { Turn } from "../../components/turn";
import { Advance } from "../../components/advance";
import app from "../../pixi/app";

export class RenderTurn extends System {
  private app: P.Application = app;
  private text: P.Text = new P.Text("End Turn", {
    fontFamily: "Monoid, monospace",
    fontSize: 16,
    fill: 0xffffff,
  });

  init() {
    this.text.x = this.app.screen.right - this.text.width - 8;
    this.text.y = this.app.screen.bottom - this.text.height - 8;

    this.text.interactive = true;

    this.app.stage.addChild(this.text);

    this.text.addListener("pointerdown", () => {
      if (this.isTurn()) {
        this.world.createEntity().addComponent(Advance);
      }
    });
  }

  private isTurn(): boolean {
    return (
      this.queries.turn.results[0]?.getComponent(Turn).state.machine.state
        .name === "playerTurn"
    );
  }

  execute() {
    if (!this.isTurn()) {
      this.text.tint = 0xc60055;
    } else {
      this.text.tint = 0xff4081;
    }
  }
}

RenderTurn.queries = {
  turn: { components: [Turn] },
};
