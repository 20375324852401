import { System, Entity } from "ecsy";
import { Turn } from "../components/turn";
import {
  InDeck,
  drawPile,
  hand,
  discardPile,
  CardStatus,
} from "../components/in-deck";
import { takeLeft } from "fp-ts/lib/Array";
import { Advance } from "../components/advance";

function shuffle<T>(arr: Array<T>) {
  let j, x;
  for (let i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = arr[i];
    arr[i] = arr[j];
    arr[j] = x;
  }

  return arr;
}

/** when in the playerStart phase, draws 5 cards and advances the turn */
export class DrawCards extends System {
  execute() {
    const turn = this.queries.turn.results[0].getComponent(Turn);
    if (
      turn.state.machine.state.name === "playerStart" &&
      hand(this.queries.deck.results).length === 0
    ) {
      let drawFrom = drawPile(this.queries.deck.results);
      let hand: Array<Entity>;
      if (drawFrom.length > 5) {
        hand = takeLeft(5)(drawFrom);
      } else {
        hand = drawFrom;
        const discard = shuffle(discardPile(this.queries.deck.results));

        drawFrom = discard.map((ent) => {
          ent.getMutableComponent(InDeck).status = CardStatus.DrawPile;
          return ent;
        });

        hand = hand.concat(takeLeft(5 - hand.length)(drawFrom));
      }

      hand.forEach((ent) => {
        ent.getMutableComponent(InDeck).status = CardStatus.Hand;
      });

      this.world.createEntity().addComponent(Advance);
    }
  }

  static queries = {
    turn: { components: [Turn] },
    deck: { components: [InDeck] },
  };
}
