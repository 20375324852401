import { makeNaniteResourceCard } from "./nanite-resource-card";
import { EntityCreator } from "../entity-creator";
import { InDeck, CardStatus } from "../components/in-deck";

export const makeStartingDeck = (creator: EntityCreator) => ({
  new: (size: number = 10) => {
    const nrc = makeNaniteResourceCard(creator);
    for (let i = 0; i < size; i++) {
      nrc.new().addComponent(InDeck, { status: CardStatus.DiscardPile });
    }
  },
});
