import { Component } from "ecsy";

/** The name of an entity */
export class Name extends Component {
  constructor(public value: string) {
    super();
  }

  copy(src: Name) {
    this.value = src.value;
  }

  reset() {
    this.value = "";
  }
}
