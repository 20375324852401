import { World } from "ecsy";
import { makeStartingDeck } from "./archetypes/starting-deck";
import { Turn } from "./components/turn";
import app from "./pixi/app";
import { AdvanceTurn } from "./systems/advanceTurn";
import { RenderDeck } from "./systems/render/deck";
import { RenderTurn } from "./systems/render/turn";
import { DrawCards } from "./systems/draw-cards";
import { RenderHand } from "./systems/render/hand";
import { DiscardHand } from "./systems/discard-hand";
import { EnvironmentAI } from "./systems/environment-ai";
import { DiscardPlayed } from "./systems/discard-played";
import { RenderResources } from "./systems/render/resources";
import { ResourcePool } from "./components/resource-pool";
import { AddResource } from "./systems/add-resource";

app.loader.load(() => {
  const w = new World();
  const startingDeck = makeStartingDeck(w);

  w.registerSystem(DrawCards)
    .registerSystem(AddResource)
    .registerSystem(DiscardHand)
    .registerSystem(EnvironmentAI)
    .registerSystem(DiscardPlayed)
    .registerSystem(AdvanceTurn);

  w.registerSystem(RenderHand)
    .registerSystem(RenderResources)
    .registerSystem(RenderTurn)
    .registerSystem(RenderDeck);

  w.createEntity().addComponent(Turn);
  w.createEntity().addComponent(ResourcePool);

  startingDeck.new(11);

  let lastTime = performance.now();
  function run() {
    const time = performance.now();
    const delta = time - lastTime;

    w.execute(delta, time);

    lastTime = time;

    requestAnimationFrame(run);
  }

  run();
});
