import { System } from "ecsy";
import { Turn } from "../components/turn";
import { TurnState } from "../turn";
import { Advance } from "../components/advance";

export class EnvironmentAI extends System {
  private isAIStart(): boolean {
    return (
      this.queries.turn.results[0]?.getComponent(Turn).state.machine.current ===
      TurnState.AIStart
    );
  }
  execute() {
    if (this.isAIStart()) {
      // TODO: Run AI - for now we're skipping to player start
      this.world.createEntity().addComponent(Advance);
      this.world.createEntity().addComponent(Advance);
      this.world.createEntity().addComponent(Advance);
    }
  }

  static queries = {
    turn: { components: [Turn] },
  };
}
