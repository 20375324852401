import * as P from "pixi.js";

export function rectangle(
  w: number,
  h: number,
  { fill, line }: { fill: P.FillStyle; line: P.LineStyle }
) {
  const r = new P.Graphics();

  r.geometry.drawShape(new P.Rectangle(0, 0, w, h), fill, line, new P.Matrix());

  return r;
}
