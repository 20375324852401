import { Component } from "ecsy";
import { LonelyResource } from "../resource";

/** a resource card */
export class Resource extends Component {
  constructor(public type: LonelyResource, public amount: number = 1) {
    super();
  }

  copy(src: Resource) {
    this.type = src.type;
    this.amount = src.amount;
  }

  reset() {
    this.amount = 0;
  }
}
