import { System } from "ecsy";
import { Turn } from "../components/turn";
import { TurnState } from "../turn";
import { InDeck, hand as inHand, CardStatus } from "../components/in-deck";
import { Advance } from "../components/advance";

export class DiscardHand extends System {
  private isEndTurn() {
    return (
      this.queries.turn.results[0]?.getComponent(Turn).state.machine.current ===
      TurnState.PlayerEnd
    );
  }

  private discardHand() {
    inHand(this.queries.deck.results).forEach((card) => {
      const inDeck = card.getMutableComponent(InDeck);
      inDeck.status = CardStatus.DiscardPile;
    });
  }

  private advanceTurn() {
    this.world.createEntity().addComponent(Advance);
  }

  execute() {
    if (this.isEndTurn()) {
      this.discardHand();
      this.advanceTurn();
    }
  }

  static queries = {
    deck: { components: [InDeck] },
    turn: { components: [Turn] },
  };
}
