import { System } from "ecsy";
import { InDeck, hand as inHand } from "../../components/in-deck";
import * as P from "pixi.js";
import { rectangle } from "../../pixi/draw";
import { Name } from "../../components/name";
import app from "../../pixi/app";
import { Play } from "../../components/play";

/** create a Pixi graphics object which will draw a card */
function makeBlankPixiCard(): P.Graphics {
  const fill = new P.FillStyle();
  const line = new P.LineStyle();

  const width = 8 * 24;
  const height = 8 * 15;

  fill.color = 0x37474f;
  fill.visible = true;

  line.color = 0xff4081;
  line.visible = true;
  line.width = 4;

  const cardBG = rectangle(width, height, { fill, line });
  const cardName = new P.Text("", {
    fontSize: 14,
    fontFamily: "Monoid, monospaced",
    fill: 0xffffff,
    resolution: 1.5,
  });

  cardName.x = 4;
  cardName.y = 16;
  cardName.name = "name";

  cardBG.addChild(cardName);

  return cardBG;
}

/** render the current hand */
export class RenderHand extends System {
  /** reuse cards, save resources */
  public cards: Array<P.Graphics> = [];
  private app: P.Application = app;

  public handChanged() {
    return (
      this.queries.deck.added !== undefined ||
      this.queries.deck.removed !== undefined
    );
  }

  execute() {
    this.queries.deck.added?.forEach(() => {
      if (this.queries.deck.results.length > this.cards.length) {
        const blank = makeBlankPixiCard();
        blank.visible = false;
        blank.interactive = true;
        this.app.stage.addChild(blank);
        this.cards.push(blank);
      }
    });

    if (this.handChanged()) {
      const hand = inHand(this.queries.deck.results);

      hand.forEach((card, index) => {
        const pixiCard = this.cards[index];
        const cardName = card.getComponent(Name).value;

        (pixiCard.getChildByName("name") as P.Text).text = cardName;
        pixiCard.removeAllListeners();
        pixiCard.addListener("pointerdown", () => {
          console.log("oi oi");
          card.addComponent(Play);
        });
      });

      const startRendering = 80;

      this.cards.forEach((x, i) => {
        if (i < hand.length) {
          x.visible = true;
          x.x = i + 1 * startRendering + i * (x.width + 8);
          x.y = this.app.screen.bottom - x.height + 8;
        } else {
          x.visible = false;
        }
      });
    }
  }

  static queries = {
    deck: {
      components: [InDeck],
      listen: {
        added: true,
        removed: true,
      },
    },
  };
}
