import * as P from "pixi.js";

const canvas = document.getElementById("lonely") as
  | HTMLCanvasElement
  | undefined;

const app = new P.Application({
  antialias: true,
  view: canvas,
  resizeTo: window,
  backgroundColor: 0x263238,
});

document.body.appendChild(app.view);
// this is a gross singleton, as there's no way to pass state into systems at init-time right now. Hoping that this will come to pass in ecsy soon.
export default app;
