import { Component } from "ecsy";
import { LonelyResource } from "../resource";

export type ResourcePoolValue = {
  [LonelyResource.Nanite]: number;
};

const defaultRPV = () => ({ [LonelyResource.Nanite]: 0 });

/** the number of each resource the player has */
export class ResourcePool extends Component {
  constructor(public value: ResourcePoolValue = defaultRPV()) {
    super();
  }

  copy(src: ResourcePool) {
    this.value[LonelyResource.Nanite] = src.value[LonelyResource.Nanite];
  }

  reset() {
    this.value = defaultRPV();
  }
}
