import { Component } from "ecsy";
import { turn } from "../turn";

export class Turn extends Component {
  public state = turn();
  constructor() {
    super();
  }

  copy(src: Turn) {
    this.state = src.state;
  }

  reset() {
    this.state = turn();
  }
}
